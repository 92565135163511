<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            name="period"
            label="신청/지급기간"
            defaultStart="-11M"
            defaultEnd="1M"
            v-model="period"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            type="search"
            :plantCd="searchParam.plantCd"
            :isFirstValue="false"
            label="신청부서"
            name="requestDeptCd"
            v-model="searchParam.requestDeptCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            :plantCd="searchParam.plantCd"
            label="신청자"
            name="requestUserId"
            v-model="searchParam.requestUserId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            type="search"
            :plantCd="searchParam.plantCd"
            :isFirstValue="false"
            label="지급부서"
            name="giveDeptCd"
            v-model="searchParam.giveDeptCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            :plantCd="searchParam.plantCd"
            label="불출담당자"
            name="giveUserId"
            v-model="searchParam.giveUserId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field
            :plantCd="searchParam.plantCd"
            label="수령인"
            name="recipientId"
            v-model="searchParam.recipientId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="SAS_GIVE_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="giveTypeCd"
            label="지급구분"
            v-model="searchParam.giveTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="SAS_STATUS_CD"
            itemText="codeName"
            itemValue="code"
            name="statusCd"
            label="진행상태"
            v-model="searchParam.statusCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="보호구 신청/지급 목록"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn v-if="editable" label="보호구신청" icon="add" @btnClicked="openRequest" />
          <c-btn v-if="editable" label="무신청지급" icon="add" @btnClicked="openUnPaid" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>

      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'requestDate'">
          <template v-if="props.row['giveTypeCd'] === 'SGT0000005'">
            <q-icon 
              name="close" 
              class="text-red"
            />
          </template>
          <template v-else>
              {{ props.row['requestDate']}}
          </template>
        </template>
        <template v-if="col.name === 'requestItems'">
          <template v-if="props.row['giveTypeCd'] === 'SGT0000005'">
            <q-icon 
              name="close" 
              class="text-red"
            />
          </template>
          <template v-else>
              {{ props.row['requestItems']}}
          </template>
        </template>
        <template v-if="col.name === 'requestDeptName'">
          <template v-if="props.row['giveTypeCd'] === 'SGT0000005'">
            <q-icon 
              name="close" 
              class="text-red"
            />
          </template>
          <template v-else>
              {{ props.row['requestDeptName']}}
          </template>
        </template>
        <template v-if="col.name === 'requestUserName'">
          <template v-if="props.row['giveTypeCd'] === 'SGT0000005'">
            <q-icon 
              name="close" 
              class="text-red"
            />
          </template>
          <template v-else>
              {{ props.row['requestUserName']}}
          </template>
        </template>

        <template v-if="col.name === 'click'">
          <q-chip
            color="blue"
            outline square
            :clickable="true"
            :editable="editable"
            text-color="white"
            @click="linkClick(props.row, col.name)">
            {{'클릭'}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-gear-request',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            style: 'width:90px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '결재진행단계',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'statusName',
            field: 'statusName',
            label: '진행단계',
            style: 'width:90px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'giveTypeName',
            field: 'giveTypeName',
            label: '지급구분',
            style: 'width:90px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'click',
            field: 'click',
            label: '상세보기',
            style: 'width:70px',
            align: 'center',
            type: 'custom',
            sortable: true,
          },
          {
            label: '신청',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'requestDate',
                field: 'requestDate',
                label: '신청일',
                style: 'width:90px',
                align: 'center',
                sortable: true,
                type: 'custom',
              },
              {
                name: 'requestItems',
                field: 'requestItems',
                label: '신청품목',
                style: 'width:230px',
                align: 'center',
                type: 'custom',
                sortable: true,
              },
              {
                name: 'requestDeptName',
                field: 'requestDeptName',
                label: '신청부서',
                style: 'width:100px',
                align: 'center',
                type: 'custom',
                sortable: true,
              },
              {
                name: 'requestUserName',
                field: 'requestUserName',
                label: '신청자',
                style: 'width:90px',
                align: 'center',
                type: 'custom',
                sortable: true,
              },
            ]
          },
          {
            label: '지급',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'giveDate',
                field: 'giveDate',
                label: '지급일',
                style: 'width:90px',
                align: 'center',
                sortable: true,
              },
              {
                name: 'giveItems',
                field: 'giveItems',
                label: '지급품목',
                style: 'width:230px',
                align: 'left',
                sortable: true,
              },
              {
                name: 'giveDeptName',
                field: 'giveDeptName',
                label: '지급부서',
                style: 'width:100px',
                align: 'center',
                sortable: true,
              },
              {
                name: 'giveUserName',
                field: 'giveUserName',
                label: '불출담당자',
                style: 'width:90px',
                align: 'center',
                sortable: true,
              },
              {
                name: 'recipientName',
                field: 'recipientName',
                label: '수령인',
                style: 'width:90px',
                align: 'center',
                sortable: true,
              },
              {
                name: 'signFlag',
                field: 'signFlag',
                label: '서명',
                align: 'center',
                sortable: true,
                type: 'proxy',
                component: () => import(`${'@/pages/sop/sas/safetyGearRecipientSign.vue'}`)
              },
            ]
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        requestDeptCd: '',
        requestUserId: '',
        giveDeptCd: '',
        giveUserId: '',
        startYmd: '',
        endYmd: '',
        statusCd: null,
        recipientId: '',
        giveTypeCd: null
      },
      period: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.sas.request.list.url;
      this.getList();
    },
    /* eslint-disable no-unused-vars */
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    openRequest() {
      this.linkClick({giveDt:'',requestGiveInfoId:'',plantCd:'',giveTypeCd:'SGT0000001'});
    },
    openUnPaid() {
      this.popupOptions.title = '보호구 무신청 지급'; 
      this.popupOptions.param = {
        requestGiveInfoId: '',
        stepCd: 'SSC0000010',
        plantCd: '',
        isRequest: 'N',
        giveTypeCd: 'SGT0000005',
      };
      this.popupOptions.target = () => import(`${'./safetyGearRequestGiveDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.width = '80%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    linkClick(row, col, index) {
      this.popupOptions.title = '보호구 신청/지급 상세'; 
      this.popupOptions.param = {
        requestGiveInfoId: row.requestGiveInfoId ? row.requestGiveInfoId : '',
        stepCd: row.statusCd ? row.statusCd : '',
        plantCd: row.plantCd ? row.plantCd : '',
        isRequest: row.giveTypeCd === 'SGT0000001' ? 'Y' : 'N',
        giveTypeCd: row.giveTypeCd ? row.giveTypeCd : 'SGT0000001',
      };
      this.popupOptions.target = () => import(`${'./safetyGearRequestGiveDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.width = '80%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>